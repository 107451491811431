import React from "react";
import Navbar from "../../components/Navbar/Navbar";
import Footer from "../../components/Footer/Footer";
import { trackCustomEvent } from "gatsby-plugin-google-analytics";

import "./LandingPage.css";

const LandingPage = () => {
  return (
    <main id={"landing-page"}>
      <Navbar />
      <section id={"hero"}>
        <h1 style={{ color: "white", fontFamily: "Fredericka the great" }}>
          Desarrollamos tus ideas
        </h1>
      </section>
      <section id={"header1"} className={"pointed-jumbotron"}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 100 100"
          preserveAspectRatio="none"
        >
          <polygon
            fill="white"
            points="0,0 0,70 50,100 100,70 100,0"
            style={{ fill: "#8155BA" }}
          />
        </svg>
        <p>
          ¿Tienes una idea visionaria, pero no sabes por donde empezar?
          <br />
          ¿O tal vez necesites renovar tu aplicación?
          <br />
          ¿O tal vez después de ver a otros proovedores te diste cuenta de que
          tienes poco presupuesto?
        </p>
      </section>
      <section id={"speech"}>
        <h2>¿Quiénes somos?</h2>
        <p>
          Somos un pequeño equipo de desarrolladores que le entusiasman las
          cosas nuevas, aportar valor y ver crecer pequeñas ideas en grandes
          proyectos. A través del uso de infraestructura, arquitectura y
          patrones de diseño de software modernos, creamos software a la medida,
          tan optimizado que tu factura de hospedaje se mantiene cercana al 0.
          Tan veloz que carga rápido en PC y celulares antiguos.
        </p>
        <h2>Nuestros valores</h2>
        <p>
          No puede existir un buen servicio sin una buena razón de ser. Porque
          son las razones los que nos impulsa a todos a seguir adelante, a ir
          más allá, a no rendirnos y a mejorar. Y estas son nuestras razones de
          ser:
        </p>
        <br />
        <br />
        <article className={"two-col icon-left"}>
          <i className={"fas fa-lightbulb"} />
          <div>
            <br />
            <h3>
              <b>Innovación</b>
            </h3>
            En un mundo en constante cambio, quien se queda quieto, se muere.
            Figurativamente hablando, claro. Así, nosotros, tenemos la necesidad
            de estar en constante movimiento, en constante aprendizaje, siempre
            mejorándonos. Nuestra premisa se resume en 3 palabras
            <b>
              <i> Cada vez mejor</i>
            </b>
            . Ningún proyecto es completamente igual al anterior, siempre
            buscamos subir la vara. Cada uno son los cimientos del siguiente, y
            todos pavimentan el camino. El camino de la innovación. Actualmente
            usamos tecnologías, arquitecturas y metodologías que suelen estar
            reservados para grandes corporativos, que tienen los recursos para
            investigar, capacitar e implementar. Usamos tecnologías como CI /
            CD, Serverless computing (nuestro ingrediente secreto),
            Microservicios, Computación en la nube, e incluso Aprendizaje
            Automático. <b>¡Y mañana, usaremos más!</b>
          </div>
        </article>
        <br />
        <br />
        <article className={"two-col icon-right"}>
          <i className={"fas fa-dove"} />
          <div>
            <br />
            <h3>
              <b>Honestidad</b>
            </h3>
            No hay negocio que no tenga como cimientos el diálogo honesto. Por
            que si, cualquiera es bueno dando las buenas noticias, pero sé
            requiere valor para dar las malas noticias. Esa es la cruda
            realidad, ¿Pero a quién prefieres? A alguien siempre te dé la razón,
            aunque eso signifique estar a ciegas, o prefieres a aquel que es
            honesto contigo, aunque duela. Alguien en cuya visión puedas
            confiar. Así nosotros tenemos por valor la honestidad, la tenemos
            desde el día 0, al acompañarte para que abras tu cuenta de la nube,
            sobre la cual trabajaremos (en vez de trabajar en las nuestras),
            para que así, tu aplicación sea realmente tuya. Honestidad desde él
            día cero en que te damos acceso al repositorio donde el código
            fuente está almacenado, para que puedas ver en tiempo real como sé
            avanza y si así lo quieres, pedir una segunda opinión a un tercero.
          </div>
        </article>
        <br />
        <br />
        <article className={"two-col icon-left"}>
          <i className={"fas fa-heart"} />
          <div>
            <br />
            <h3>
              <b>Pasión</b>
            </h3>
            No se puede ser realmente bueno en algo si a uno no le gusta. Y esto
            es lo más importante, porque sin amor, no hay innovación ni hay
            honestidad, ni calidad. Es nuestra pasión lo que nos impulsa, nos
            motiva y nos hace crecer. Compara la diferencia entre la calidad del
            trabajo de alguien que hace por obligación y otro que hace por amor,
            con pasión. <b>¡Notarás la diferencia!</b>
          </div>
        </article>
      </section>
      <br />
      <br />
      <section id={"services-header"} className={"pointed-jumbotron"}>
        <svg
          style={{ zIndex: 2 }}
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 100 100"
          preserveAspectRatio="none"
        >
          <polygon
            fill="white"
            points="0,0 50,30 100,0"
            style={{ fill: "#fff" }}
          />
        </svg>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 100 100"
          preserveAspectRatio="none"
        >
          <polygon
            fill="white"
            points="0,0 0,70 50,100 100,70 100,0"
            style={{ fill: "#8155BA" }}
          />
        </svg>
        <p style={{ fontSize: "2rem", paddingTop: "9rem" }}>
          Nuestros Servicios
        </p>
      </section>
      <section id={"services"}>
        <p>Algunos de nuestros servicios son los siguientes:</p>
        <br />
        <section id={"services-portfolio"}>
          <article>
            <i className={"fas fa-code"} />
            <p>Desarrollo de aplicaciones web</p>
          </article>
          <article>
            <i className={"fas fa-shield-alt"} />
            <p>
              Consultoría y auditoria de seguridad y mejores practicas en
              desarrollo.
            </p>
          </article>
          <article>
            <i className={"fas fa-server"} />
            <p>Gestion de infraestructura</p>
          </article>
          <article>
            <i className="fas fa-paint-brush" />
            <p>Diseño web</p>
          </article>
          <article>
            <i className={"fas fa-search"} />
            <p>Analisis descriptivo y predictivo de datos</p>
          </article>
          <article>
            <i className="fas fa-infinity" />
            <p>Automatización de la entrega de aplicaciones</p>
          </article>
        </section>
      </section>
      <br />
      <br />
      <br />
      <section id={"contact"}>
        <h2>¡Contáctanos!</h2>
        <br />
        <div>
          <a
            href="https://api.whatsapp.com/send?phone=5218003230769&text=¡Hola%2C%20vi%20su%20pagina%20y%20estoy%20interesado%20en%20contactar%20un%20servicio!"
            onClick={() => {
              trackCustomEvent({
                category: "CTA",
                action: "click",
                label: "Whatsapp",
              });
            }}
          >
            <i className={"fab fa-whatsapp"} />
            <br />
            <span>+52 800 323 0769</span>
          </a>
          <a
            href="mailto:hello@gemini-is.com"
            onClick={() => {
              trackCustomEvent({
                category: "CTA",
                action: "click",
                label: "Whatsapp",
              });
            }}
          >
            <i className="fa fa-envelope" />
            <br />
            <span>hello@gemini-is.com</span>
          </a>
        </div>
      </section>
      <br />
      <br />
      <br />
      <Footer />
    </main>
  );
};

export default LandingPage;
