import React from "react";
import "./Footer.css";
import { version } from "../../../package.json";
function Footer() {
  return (
    <footer id={"main-footer"} style={{ boxSizing: "border-box" }}>
      <section id="footer-contacts">
        <article>
          <a href="https://api.whatsapp.com/send?phone=5218003230769&text=%C2%A1Hola%2C%20vi%20su%20pagina%20y%20estoy%20interesado%20en%20contactar%20un%20servicio!">
            <i className={"fab fa-whatsapp"} />
          </a>
          <a href="mailto:hello@gemini-is.com">
            <i className="fa fa-envelope" />
          </a>
        </article>
      </section>
      <section id="footer-copyright">
        <article>
          <p>Aviso de privacidad</p>
        </article>
        <article>
          <p>
            Copyright © 2021 Gemini Integrated Solutions
            <br />
            All Rights Reserved
          </p>
        </article>
        <article>
          <p>v{version}</p>
          <p>Developed by</p>
          <a
            href="https://gemini-is.com"
            style={{ textDecoration: "none", color: "white" }}
          >
            <strong>GEMINI-IS</strong>
          </a>
        </article>
      </section>
    </footer>
  );
}

export default Footer;
